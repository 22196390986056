import { navigate } from 'gatsby';

import { isBrowser } from '../utils';
import { useAuthentication } from '../hooks/useAuthentication';
import { ROUTES } from '../utils/constants';

const IndexPage = () => {
  const { user } = useAuthentication();

  if (isBrowser) {
    if (user) {
      navigate(ROUTES.HOME);
    } else {
      navigate(ROUTES.LOGIN);
    }
  }

  return null;
};

export default IndexPage;
